import { AxiosInstance, CancelToken } from "axios";
import deviceStorage from "../deviceStorage";
import domains from "../domains";
import EnvironmentHandler from "../environmentHandler";
import storageNames from "../storageNames";
import AxiosProvider from "./axiosProvider";

export const baseUrlGateway = "/gateway/request";
export const baseUrlMasterService = "/api";

export const getDomain = () => {
    var serverDomain = deviceStorage.getItem(storageNames.serverDomain);
    //serverDomain = null;

    if(serverDomain === null) {
        if(EnvironmentHandler.isLocalEnvironment()) {
            deviceStorage.saveItem(storageNames.serverDomain, domains.gateway_local);
            return domains.gateway_local;
        } else if(EnvironmentHandler.isTestEnvironment() || EnvironmentHandler.isStageEnvironment()) {
            deviceStorage.saveItem(storageNames.serverDomain, domains.gateway_test);
            return domains.gateway_test;
        } else {
            deviceStorage.saveItem(storageNames.serverDomain, domains.gateway_prod);
            return domains.gateway_prod;
        }
    } else {
        return serverDomain;
    }
}

export const getDefaultAxios = (): AxiosInstance => {
    return AxiosProvider.getInstance().getGatewayAxios();
}

export const getUploadAxios = (): AxiosInstance => {
    return AxiosProvider.getInstance().getUploadAxios();
}

export const getCancelToken = (): CancelToken => {
    return AxiosProvider.getInstance().getCancelToken().token;
}

export function buildUrl(service: string, controller: string, endpoint: string, parameter: string | null = null, query: string = "") {
    if(parameter == null) {
        return baseUrlGateway + "?service=" + service + "&controller=" +  controller + "&endpoint=" + endpoint + query;
    } else {
        return baseUrlGateway + "?service=" + service + "&controller=" +  controller + "&endpoint=" + endpoint + "&parameter=" + parameter + query;
    }
}

export function buildUploadUrl(service: string, controller: string, endpoint: string, parameter: string | null = null, query = "") {
    if(parameter == null) {
        return "/gateway/fileupload?service=" + service + "&controller=" +  controller + "&endpoint=" + endpoint + query;
    } else {
        return "/gateway/fileupload?service=" + service + "&controller=" +  controller + "&endpoint=" + endpoint + "&parameter=" + parameter + query;
    }
}