import axios, { AxiosResponse, CancelTokenSource, CancelTokenStatic } from "axios";
import { getCurrentDateTimeUTC, minusMinutes } from "../../common/utils";
import SimpleUserData from "../../models/SimpleUserData";
import deviceStorage from "../deviceStorage";
import storageNames from "../storageNames";
import jwt_decode from 'jwt-decode';
import domains from "../domains";
import User from "../../models/User";
import CreateRequest from "../../models/CreateRequest";
import Location from "../../models/locations/Location";
import ProductCategory from "../../models/products/ProductCategory";
import Product from "../../models/products/Product";
import LanguageValue from "../../models/LanguageValue";
import Language from "../../models/Language";
import Order, { OrderState } from "../../models/orders/Order";
import PaymentTO from "../../models/payments/PaymentTO";
import PaymentMethod from "../../models/payments/PaymentMethod";
import { PaymentState } from "../../models/payments/PaymentState";
import Room from "../../models/FloorPlan/Room";
import Table from "../../models/FloorPlan/Table";
import Floor from "../../models/FloorPlan/Floor";
import Booking from "../../models/Booking";
import BookingRequest from "../../models/BookingRequest";
import CreateOrderRequest from "../../models/orders/requests/CreateOrderRequest";
import CreatePaymentRequest from "../../models/payments/CreatePaymentRequest";
import UserPaymentMethod from "../../models/payments/UserPaymentMethod";
import BookingUser from "../../models/BookingUser";
import LocationSearchRequest from "../../models/LocationSearchRequest";
import UserFileTO from "../../models/UserFileTO";
import Tag from "../../models/Tag";
import StaffMember from "../../models/StaffMember";
import OpeningHour from "../../models/OpeningHour";
import LocationImage from "../../models/LocationImage";
import Review from "../../models/Review";
import OrderProduct from "../../models/orders/OrderProduct";
import PreviewOrderRequest from "../../models/PreviewOrderRequest";
import Address from "../../models/orders/Address";
import LocationPlan from "../../models/LocationPlan";
import StaffInvitation from "../../models/StaffInvitation";
import RegisterUserRequest from "../../models/RegisterUserRequest";
import Plan from "../../models/Plan";
import Allergen from "../../models/Allergen";
import Additive from "../../models/Additive";
import ProductAllergen from "../../models/products/ProductAllergen";
import ProductAdditive from "../../models/products/ProductAdditive";
import MainProductCategory from "../../models/products/MainProductCategory";
import ProductVariation from "../../models/products/ProductVariation";
import MenuSettings from "../../models/locations/MenuSettings";
import Option from "../../models/products/Option";
import OptionValue from "../../models/products/OptionValue";
import ProductCategoryOption from "../../models/ProductCategoryOption";
import EnvironmentHandler from "../environmentHandler";
import CashRegisterSystem from "../../models/CashRegisterSystem";
import LocationCashRegister from "../../screens/cashregister/LocationCashRegister";
import Ticket from "../../models/Ticket";
import TokenHandler from "./tokenHandler";
import { getDomain, getDefaultAxios, baseUrlGateway, getUploadAxios, getCancelToken } from "./requestUtils";
import { baseUrlMasterService } from "./requestUtils";
import OrderTO from "../../models/orders/dtos/OrderTO";

export const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key: string, value: any) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
};

function BuildUrl(service: string, controller: string, endpoint: string, parameter: string | null = null, query: string = "") {
    if(parameter == null) {
        return baseUrlGateway + "?service=" + service + "&controller=" +  controller + "&endpoint=" + endpoint + query;
    } else {
        return baseUrlGateway + "?service=" + service + "&controller=" +  controller + "&endpoint=" + endpoint + "&parameter=" + parameter + query;
    }
}

function BuildUploadUrl(service: string, controller: string, endpoint: string, parameter: string | null = null, query = "") {
    if(parameter == null) {
        return "/gateway/fileupload?service=" + service + "&controller=" +  controller + "&endpoint=" + endpoint + query;
    } else {
        return "/gateway/fileupload?service=" + service + "&controller=" +  controller + "&endpoint=" + endpoint + "&parameter=" + parameter + query;
    }
}

export default class RequestManager {

    static myInstance: RequestManager;



    /**
     * @returns {RequestManager}
     */
    static getInstance(): RequestManager {
        if (RequestManager.myInstance === null || RequestManager.myInstance === undefined) {
            RequestManager.myInstance = new RequestManager();
        }

        return this.myInstance;
    }

    static getDownloadUrl(userFileId: number, fileName: string) {
        var domain = getDomain();

        return domain + baseUrlMasterService + BuildUrl("file_service", "userfiles", "download", userFileId.toString(), `&fileName=${fileName}`);
    }

    async createTicket(ticket: Ticket): Promise<void> {
        return await getDefaultAxios().post(BuildUrl("heartbeat_user_service", "tickets", "create"), ticket, {
            cancelToken: getCancelToken(),
        });
    }

    async getCashRegister(locationId: number): Promise<LocationCashRegister> {
        return await getDefaultAxios().get(BuildUrl("cash_register_service", "locationCashRegisters", "get", locationId.toString()), {
            cancelToken: getCancelToken(),
        });
    }

    async linkCashRegister(createRequest: CreateRequest) {
        return await getDefaultAxios().post(BuildUrl("cash_register_service", "locationCashRegisters", "create"), createRequest, {
            cancelToken: getCancelToken(),
        });
    }

    async importProducts(locationId: number) {
        return await getDefaultAxios().put(BuildUrl("cash_register_service", "products", "import", locationId.toString()), "{}", {
            cancelToken: getCancelToken(),
        });
    }

    async createProduct(product: Product): Promise<Product> {
        return await getDefaultAxios().post(BuildUrl("booking_service", "products", "create"), product, {
            cancelToken: getCancelToken(),
        });
    }

    async updateProduct(productId: number, product: Product) {
        return await getDefaultAxios().put(BuildUrl("booking_service", "products", "update", productId.toString()), product, {
            cancelToken: getCancelToken(),
        });
    }

    async deleteProduct(productId: number) {
        return await getDefaultAxios().delete(BuildUrl("booking_service", "products", "delete", productId.toString()), {
            cancelToken: getCancelToken(),
            data: "{}" //Requires empty body
        });
    }

    async getAllergens(): Promise<Allergen[]> {
        return await getDefaultAxios().get(BuildUrl("booking_service", "allergenes", "get"), {
            cancelToken: getCancelToken(),
        });
    }

    async getProductAllergens(id: number): Promise<Allergen[]> {
        return await getDefaultAxios().get(BuildUrl("booking_service", "products", "allergens", id.toString()), {
            cancelToken: getCancelToken(),
        });
    }

    async createProductAllergen(productAllergen: ProductAllergen): Promise<void> {
        return await getDefaultAxios().post(BuildUrl("booking_service", "productallergens", "create"), productAllergen, {
            cancelToken: getCancelToken(),
        });
    }

    async updateProductAllergens(productId: number, allergens: Allergen[]): Promise<void> {
        return await getDefaultAxios().put(BuildUrl("booking_service", "productallergens", "update", null, `&productId=${productId}`), allergens, {
            cancelToken: getCancelToken(),
        });
    }

    async deleteProductAllergen(productId: number, allergenId: number): Promise<void> {
        return await getDefaultAxios().post(BuildUrl("booking_service", "productallergens", "delete", null, `&productId=${productId}&allergenId=${allergenId}`), {
            cancelToken: getCancelToken(),
            data: "{}" //Requires empty body
        });
    }

    async getAdditives(): Promise<Additive[]> {
        return await getDefaultAxios().get(BuildUrl("booking_service", "additives", "get"), {
            cancelToken: getCancelToken(),
        });
    }

    async getProductAdditives(id: number): Promise<Additive[]> {
        return await getDefaultAxios().get(BuildUrl("booking_service", "products", "additives", id.toString()), {
            cancelToken: getCancelToken(),
        });
    }

    async createProductAdditive(productAdditive: ProductAdditive): Promise<void> {
        return await getDefaultAxios().post(BuildUrl("booking_service", "productadditives", "create"), productAdditive, {
            cancelToken: getCancelToken(),
        });
    }

    async updateProductAdditives(productId: number, additives: Additive[]): Promise<void> {
        return await getDefaultAxios().put(BuildUrl("booking_service", "productadditives", "update", null, `&productId=${productId}`), additives, {
            cancelToken: getCancelToken(),
        });
    }

    async deleteProductAdditive(productId: number, additiveId: number): Promise<void> {
        return await getDefaultAxios().post(BuildUrl("booking_service", "productadditives", "delete", null, `&productId=${productId}&additiveId=${additiveId}`), {
            cancelToken: getCancelToken(),
            data: "{}" //Requires empty body
        });
    }

    

    async getProductVariations(id: number): Promise<Additive[]> {
        return await getDefaultAxios().get(BuildUrl("booking_service", "products", "additives", id.toString()), {
            cancelToken: getCancelToken(),
        });
    }

    async createProductVariation(productVariation: ProductVariation): Promise<void> {
        return await getDefaultAxios().post(BuildUrl("booking_service", "productvariations", "create"), productVariation, {
            cancelToken: getCancelToken(),
        });
    }

    async updateProductVariation(id: number, productVariation: ProductVariation): Promise<void> {
        return await getDefaultAxios().put(BuildUrl("booking_service", "productvariations", "update", id.toString()), productVariation, {
            cancelToken: getCancelToken(),
        });
    }

    async processProductVariations(productId: number, productVariations: ProductVariation[]): Promise<void> {
        return await getDefaultAxios().put(BuildUrl("booking_service", "productvariations", "process", null, `&productId=${productId}`), productVariations, {
            cancelToken: getCancelToken(),
        });
    }

    async deleteProductVariation(id: number): Promise<void> {
        return await getDefaultAxios().post(BuildUrl("booking_service", "productvariations", "delete", id.toString()), {
            cancelToken: getCancelToken(),
            data: "{}" //Requires empty body
        });
    }

    async createProductCategory(productCategory: ProductCategory, translate: boolean): Promise<ProductCategory> {
        return await getDefaultAxios().post(BuildUrl("booking_service", "productcategories", "create", null, `&translate=${translate}`), productCategory, {
            cancelToken: getCancelToken(),
        });
    }

    async updateProductCategory(productCategory: ProductCategory, translate: boolean): Promise<void> {
        return await getDefaultAxios().put(BuildUrl("booking_service", "productcategories", "update", productCategory.id.toString(), `&translate=${translate}`), productCategory, {
            cancelToken: getCancelToken(),
        });
    }

    async deleteProductCategory(id: number): Promise<void> {
        return await getDefaultAxios().delete(BuildUrl("booking_service", "productcategories", "delete", id.toString()), {
            cancelToken: getCancelToken(),
            data: "{}" //Requires empty body
        });
    }


    async createMainProductCategory(mainProductCategory: MainProductCategory, translate: boolean): Promise<MainProductCategory> {
        return await getDefaultAxios().post(BuildUrl("booking_service", "mainproductcategories", "create", null, `&translate=${translate}`), mainProductCategory, {
            cancelToken: getCancelToken(),
        });
    }

    async updateMainProductCategory(mainProductCategory: MainProductCategory, translate: boolean): Promise<void> {
        return await getDefaultAxios().put(BuildUrl("booking_service", "mainproductcategories", "update", mainProductCategory.id.toString(), `&translate=${translate}`), mainProductCategory, {
            cancelToken: getCancelToken(),
        });
    }

    async deleteMainProductCategory(id: number): Promise<void> {
        return await getDefaultAxios().delete(BuildUrl("booking_service", "mainproductcategories", "delete", id.toString()), {
            cancelToken: getCancelToken(),
            data: "{}" //Requires empty body
        });
    }

    async getOptions(locationCode: string): Promise<Option[]> {
        return await getDefaultAxios().get(BuildUrl("booking_service", "options", "get", null, `&locationCode=${locationCode}`), {
            cancelToken: getCancelToken(),
        });
    }

    async createOption(option: Option, translate: boolean): Promise<void> {
        return await getDefaultAxios().post(BuildUrl("booking_service", "options", "create", null, `&translate=${translate}`), option, {
            cancelToken: getCancelToken(),
        });
    }

    async updateOption(option: Option, translate: boolean): Promise<void> {
        return await getDefaultAxios().put(BuildUrl("booking_service", "options", "update", option.id.toString(), `&translate=${translate}`), option, {
            cancelToken: getCancelToken(),
        });
    }

    async deleteOption(optionId: number): Promise<void> {
        return await getDefaultAxios().delete(BuildUrl("booking_service", "options", "delete", optionId.toString()), {
            cancelToken: getCancelToken(),
            data: "{}" //Requires empty body
        });
    }

    async createOptionValue(optionValue: OptionValue, translate: boolean): Promise<void> {
        return await getDefaultAxios().post(BuildUrl("booking_service", "optionvalues", "create", null, `&translate=${translate}`), optionValue, {
            cancelToken: getCancelToken(),
        });
    }

    async updateOptionValue(optionValue: OptionValue, translate: boolean): Promise<void> {
        return await getDefaultAxios().put(BuildUrl("booking_service", "optionvalues", "update", optionValue.id.toString(), `&translate=${translate}`), optionValue, {
            cancelToken: getCancelToken(),
        });
    }

    async deleteOptionValue(optionValueId: number, optionId: number): Promise<void> {
        return await getDefaultAxios().delete(BuildUrl("booking_service", "optionvalues", "delete", optionValueId.toString(), `&optionId=${optionId}`), {
            cancelToken: getCancelToken(),
            data: "{}" //Requires empty body
        });
    }

    async createProductCategoryOption(categoryOption: ProductCategoryOption): Promise<void> {
        return await getDefaultAxios().post(BuildUrl("booking_service", "productcategoryoptions", "create", null), categoryOption, {
            cancelToken: getCancelToken(),
        });
    }

    async deleteProductCategoryOption(categoryOption: ProductCategoryOption): Promise<void> {
        return await getDefaultAxios().delete(BuildUrl("booking_service", "productcategoryoptions", "delete", `&productCategoryId=${categoryOption.productCategoryId}&optionId=${categoryOption.optionId}`), {
            cancelToken: getCancelToken(),
            data: "{}" //Requires empty body
        });
    }


    async getMenu(locationCode: string): Promise<MainProductCategory[]> {
        return await getDefaultAxios().get(BuildUrl("booking_service", "products", "menu", locationCode), {
            cancelToken: getCancelToken(),
        });
    }
    
    async uploadProductImage(productId: number, file: any) {
        const formData = new FormData();
        formData.append("files", file);

        return await getUploadAxios().post(BuildUploadUrl("booking_service", "products", "upload", productId.toString()), formData, {
            cancelToken: getCancelToken()
        });
    }
 
    async uploadProductCategoryImage(productCategoryId: number, locationId: number, file: any): Promise<void> {
        const formData = new FormData();
        formData.append("files", file);

        return await getUploadAxios().post(BuildUploadUrl("booking_service", "productcategories", "upload", productCategoryId.toString(), `&locationId=${locationId}`), formData, {
            cancelToken: getCancelToken()
        });
    }
 
    async uploadMainProductCategoryImage(mainProductCategoryId: number, file: any): Promise<void>  {
        const formData = new FormData();
        formData.append("files", file);

        return await getUploadAxios().post(BuildUploadUrl("booking_service", "mainproductcategories", "upload", mainProductCategoryId.toString()), formData, {
            cancelToken: getCancelToken()
        });
    }

    async getOwnLocations(): Promise<Location[]> {
        return await getDefaultAxios().get(BuildUrl("location_service", "location", "getownlocations"), {
            cancelToken: getCancelToken(),
            withCredentials: true
        });
    }

    async getLocations(request: LocationSearchRequest): Promise<Location[]> {
        return await getDefaultAxios().post(BuildUrl("location_service", "location", "getlocations"), request, {
            cancelToken: getCancelToken(),
        });
    }

    /**
     * Gets some more information of locations like tags and rank. Can only used by hosts for their own locations.
     * @param id The ID of the locations
     * @returns The requested location.
     */
    async findLocation(id: number): Promise<Location> {
        return await getDefaultAxios().get(BuildUrl("location_service", "Location", "Find", id.toString()), {
            cancelToken: getCancelToken(),
        });
    }

    /**
     * Gets simple information about a location.
     * @param locationCode The code of the location.
     * @returns The requested location.
     */
    async getLocation(locationCode: string): Promise<Location> {
        return await getDefaultAxios().get(BuildUrl("location_service", "Location", "Get", locationCode), {
            cancelToken: getCancelToken(),
        });
    }

    /**
     * Gets detailed information about a location.
     * @param id The ID of the location.
     * @returns The requested location.
     */
    async getLocationDetails(id: number): Promise<Location> {
        return await getDefaultAxios().put(BuildUrl("location_service", "Location", "details", id.toString()), "{}", {
            cancelToken: getCancelToken(),
        });
    }

    async getLocationFloorPlan(id: number): Promise<Location> {
        return await getDefaultAxios().get(BuildUrl("location_service", "Location", "FloorPlan", id.toString()), {
            cancelToken: getCancelToken(),
        });
    }

    async getOpeningHours(locationId: number): Promise<OpeningHour[]> {
        return await getDefaultAxios().get(BuildUrl("location_service", "openinghour", "find", locationId.toString()), {
            cancelToken: getCancelToken(),
        });
    }

    async updateOpeningHours(locationId: number, openingHours: OpeningHour[]): Promise<OpeningHour[]> {
        return await getDefaultAxios().put(BuildUrl("location_service", "openinghour", "update", locationId.toString()), openingHours, {
            cancelToken: getCancelToken(),
        });
    }

    async getLocationImages(locationId: number): Promise<LocationImage[]> {
        return await getDefaultAxios().get(BuildUrl("file_service", "image", "getall", locationId.toString()), {
            cancelToken: getCancelToken(),
        });
    }

    async uploadLocationImages(locationId: number, files: File[]): Promise<UserFileTO[]> {
        const formData = new FormData();
        for (let index = 0; index < files.length; index++) {
            const file = files[index];
            formData.append("files", file);
        }

        return await getUploadAxios().post(BuildUploadUrl("file_service", "image", "upload", locationId.toString()), formData, {
            cancelToken: getCancelToken()
        });
    }

    async deleteUserFile(id: number): Promise<void> {
        return await getDefaultAxios().delete(BuildUrl("file_service", "image", "delete", id.toString()), {
            cancelToken: getCancelToken(),
        });
    }

    async uploadThumbnail(locationId: number, file: any): Promise<UserFileTO> {
        const formData = new FormData();
        formData.append("files", file);

        return await getUploadAxios().post(BuildUploadUrl("file_service", "image", "UploadThumbnail", locationId.toString()), formData, {
            cancelToken: getCancelToken()
        });
    }
 
    async uploadIntroImage(locationId: number, file: any): Promise<UserFileTO> {
        const formData = new FormData();
        formData.append("files", file);

        return await getUploadAxios().post(BuildUploadUrl("location_service", "location", "uploadintroimage", locationId.toString()), formData, {
            cancelToken: getCancelToken()
        });
    }
 
    async uploadLogo(locationId: number, file: any): Promise<UserFileTO> {
        const formData = new FormData();
        formData.append("files", file);

        return await getUploadAxios().post(BuildUploadUrl("location_service", "location", "uploadlogo", locationId.toString()), formData, {
            cancelToken: getCancelToken()
        });
    }

    async getStaffUsers(locationId: number): Promise<StaffMember[]> {
        return await getDefaultAxios().get(BuildUrl("user_service", "staff", "all", locationId.toString()), {
            cancelToken: getCancelToken(),
        });
    }

    async removeStaffUser(userId: number, locationId: number): Promise<void> {
        return await getDefaultAxios().put(BuildUrl("user_service", "staff", "remove", null, `&userId=${userId}&locationId=${locationId}`), "{}", {
            cancelToken: getCancelToken(),
        });
    }

    async addStaffUserRole(userCode: string, locationId: number, role: string): Promise<void> {
        return await getDefaultAxios().put(BuildUrl("user_service", "staff", "role", null, `&userCode=${userCode}&locationId=${locationId}&role=${role}`), "{}", {
            cancelToken: getCancelToken(),
        });
    }

    async removeStaffUserRole(userId: number, locationId: number, role: string): Promise<void> {
        return await getDefaultAxios().put(BuildUrl("user_service", "staff", "roleremove", null, `&userId=${userId}&locationId=${locationId}&role=${role}`), "{}", {
            cancelToken: getCancelToken(),
        });
    }

    async getOpenStaffUserInvitations(locationId: number): Promise<StaffInvitation[]> {
        var status: number = 0;

        return await getDefaultAxios().get(BuildUrl("user_service", "staffinvitations", "find", null, `&locationId=${locationId}&status=${status}`), {
            cancelToken: getCancelToken(),
        });
    }

    async getStaffUserInvitation(id: number, code: string): Promise<StaffInvitation> {
        return await getDefaultAxios().get(BuildUrl("user_service", "staffinvitations", "get", id.toString(), `&code=${code}`), {
            cancelToken: getCancelToken(),
        });
    }

    async createStaffUserInvitation(locationId: number, payload: string, payloadType: number): Promise<void> {
        return await getDefaultAxios().post(BuildUrl("user_service", "staffinvitations", "create", null, `&locationId=${locationId}&payload=${payload}&payloadType=${payloadType}`), "{}", {
            cancelToken: getCancelToken(),
        });
    }

    async acceptStaffUserInvitation(id: number, code: string): Promise<void> {
        return await getDefaultAxios().put(BuildUrl("user_service", "staffinvitations", "accept", id.toString(), `&code=${code}`), "{}", {
            cancelToken: getCancelToken(),
        });
    }

    async declineStaffUserInvitation(id: number, locationId : number): Promise<void> {
        return await getDefaultAxios().put(BuildUrl("user_service", "staffinvitations", "decline", id.toString(), `&locationId=${locationId}`), "{}", {
            cancelToken: getCancelToken(),
        });
    }

    async getStaffRoles(): Promise<string[]> {
        return await getDefaultAxios().get(BuildUrl("user_service", "staff", "roles"), {
            cancelToken: getCancelToken(),
        });
    }

    async getTags(): Promise<Tag[]> {
        return await getDefaultAxios().get(BuildUrl("location_service", "tags", "get"), {
            cancelToken: getCancelToken(),
        });
    }
    
    async getLanguages(): Promise<Language[]> {
        return await getDefaultAxios().get(BuildUrl("communication_service", "language", "All"), {
            cancelToken: getCancelToken(),
        });
    }

    async getLanguage(id: number) {
        return await getDefaultAxios().get(BuildUrl("communication_service", "language", "Find", id.toString()), {
            cancelToken: getCancelToken(),
        });
    }

    async getLanguageByCode(code: string, locationCode: string | null = null): Promise<LanguageValue[]> {
        return await getDefaultAxios().get(BuildUrl("communication_service", "language", "FindByCode", code, locationCode === null ? "" : "&locationCode=" + locationCode), {
            cancelToken: getCancelToken(),
        });
    }

    async getActiveBooking(): Promise<Booking> {
        return await getDefaultAxios().get(BuildUrl("booking_service", "bookings", "active"), {
            cancelToken: getCancelToken(),
        });
    }

    async createBooking(request: BookingRequest): Promise<Booking> {
        return await getDefaultAxios().post(BuildUrl("booking_service", "bookings", "create"), request, {
            cancelToken: getCancelToken(),
        });
    }

    async createBookingForOthers(request: BookingRequest, locationId: number): Promise<Booking> {
        return await getDefaultAxios().post(BuildUrl("booking_service", "bookings", "createforothers", null, `&locationId=${locationId}`), request, {
            cancelToken: getCancelToken(),
        });
    }

    async updateBooking(booking: Booking): Promise<Booking> {
        return await getDefaultAxios().put(BuildUrl("booking_service", "bookings", "update", booking.id.toString()), booking, {
            cancelToken: getCancelToken(),
        });
    }

    async joinBooking(locationId: number, tableCode: string, joinCode: string): Promise<Booking> {
        return await getDefaultAxios().put(BuildUrl("booking_service", "bookings", "join", "", `&locationId=${locationId}&tableCode=${tableCode}&joinCode=${joinCode}`), "{}", {
            cancelToken: getCancelToken(),
        });
    }

    async getBookingsOfLocation(locationId: number): Promise<Booking[]> {
        return await getDefaultAxios().get(BuildUrl("booking_service", "bookings", "ActiveOfLocation", null, `&locationId=${locationId}`), {
            cancelToken: getCancelToken(),
        });
    }

    async getBookingForTable(tableCode: string, locationId: number): Promise<Booking> {
        return await getDefaultAxios().get(BuildUrl("booking_service", "bookings", "GetForTable", null, `&tableCode=${tableCode}&locationId=${locationId}`), {
            cancelToken: getCancelToken(),
        });
    }

    async getBookingUsers(bookingId: number): Promise<BookingUser[]> {
        return await getDefaultAxios().get(BuildUrl("booking_service", "bookings", "getusers", bookingId.toString()), {
            cancelToken: getCancelToken(),
        });
    }

    async removeBookingUser(bookingId: number, code: string) {
        return await getDefaultAxios().put(BuildUrl("booking_service", "bookings", "removeuser", bookingId.toString(), `&userCode=${code}`), "{}", {
            cancelToken: getCancelToken(),
        });
    }

    async getOrderPreview(request: PreviewOrderRequest): Promise<PaymentTO> {
        return await getDefaultAxios().put(BuildUrl("payment_service", "payments", "previeworder"), request, {
            cancelToken: getCancelToken(),
        });
    }

    async completeBooking(bookingId: number): Promise<void> {
        return await getDefaultAxios().put(BuildUrl("booking_service", "bookings", "complete", bookingId.toString()), "{}", {
            cancelToken: getCancelToken(),
        });
    }

    async createOrder(request: CreateOrderRequest): Promise<Order> {
        return await getDefaultAxios().post(BuildUrl("booking_service", "orders", "create"), request);
    }

    async getBookingOrders(bookingId: number): Promise<OrderTO[]> {
        return await getDefaultAxios().get(BuildUrl("booking_service", "bookings", "orders", bookingId.toString()), {
            cancelToken: getCancelToken(),
        });
    }

    async getOrdersHost(locationId: number, orderState: OrderState | null = null, bookingId: number | null = null): Promise<OrderTO[]> {
        let query = "";
        if(orderState) {
            query += `&orderState=${orderState}`;
        }
        if(bookingId) {
            query += `&bookingId=${bookingId}`;
        }

        return await getDefaultAxios().get(BuildUrl("booking_service", "orders", "active", locationId.toString(), query), {
            cancelToken: getCancelToken(),
        });
    }

    async updateOrderState(orderState: OrderState, order: Order) {
        if(orderState === OrderState.InProgress) {
            return await getDefaultAxios().put(BuildUrl("booking_service", "orders", "inprogress"), order);
        } else if(orderState === OrderState.DeliveryPending) {
            return await getDefaultAxios().put(BuildUrl("booking_service", "orders", "delpending"), order);
        } else if(orderState === OrderState.Done) {
            return await getDefaultAxios().put(BuildUrl("booking_service", "orders", "done"), order);
        } else if(orderState === OrderState.Cancelled) {
            return await getDefaultAxios().put(BuildUrl("booking_service", "orders", "cancel"), order);
        }
    }

    async getPaymentMethods(): Promise<PaymentMethod[]> {
        return await getDefaultAxios().get(BuildUrl("payment_service", "payments", "methods"), {
            cancelToken: getCancelToken(),
        });
    }

    async cashReceived(id: number, locationId: number, tip: number) {
        return await getDefaultAxios().put(BuildUrl("payment_service", "payments", "cashReceived", id.toString(), "&locationId=" + locationId + "&tip=" + tip), "{}");
    }

    async cashedUp(id: number, locationId: number, tip: number, pan: string) {
        return await getDefaultAxios().put(BuildUrl("payment_service", "payments", "cashedup", id.toString(), "&locationId=" + locationId + "&tip=" + tip + "&pan=" + pan), "{}");
    }

    async getPayments(locationId: number, paymentState: PaymentState | null = null): Promise<PaymentTO[]> {
        return await getDefaultAxios().get(BuildUrl("payment_service", "payments", "get", locationId.toString(), paymentState === null ? "" : "&paymentState=" + paymentState), {
            cancelToken: getCancelToken(),
        });
    }

    async createLocation(location: Location, locationType: string): Promise<Location> {
        return await getDefaultAxios().post(BuildUrl("location_service", "Location", "create", "", `&locationType=${locationType}`), location, {
            cancelToken: getCancelToken(),
        });
    }
    
    async updateLocation(location: Location) {
        return await getDefaultAxios().put(BuildUrl("location_service", "Location", "update", location.id?.toString()), location, {
            cancelToken: getCancelToken(),
        });
    }
    
    async updateMenuSettings(menuSettings: MenuSettings) {
        return await getDefaultAxios().put(BuildUrl("location_service", "Location", "menusettings"), menuSettings, {
            cancelToken: getCancelToken(),
        });
    }

    async getRoom(roomId: number): Promise<Room> {
        return await getDefaultAxios().get(BuildUrl("location_service", "room", "get", roomId.toString()), {
            cancelToken: getCancelToken(),
        });
    }

    async createRoom(room: Room): Promise<Room> {
        return await getDefaultAxios().post(BuildUrl("location_service", "room", "create"), room, {
            cancelToken: getCancelToken(),
        });
    }

    async updateRoom(room: Room) {
        return await getDefaultAxios().put(BuildUrl("location_service", "room", "update", room.id?.toString()), room, {
            cancelToken: getCancelToken(),
        });
    }

    async createFloor(floor: Floor): Promise<Floor> {
        return await getDefaultAxios().post(BuildUrl("location_service", "floor", "create"), floor, {
            cancelToken: getCancelToken(),
        });
    }
    
    async createTable(table: Table) {
        return await getDefaultAxios().post(BuildUrl("location_service", "table", "create"), table, {
            cancelToken: getCancelToken(),
        });
    }

    async updateTable(table: Table) {
        return await getDefaultAxios().put(BuildUrl("location_service", "table", "update", table.id?.toString()), table, {
            cancelToken: getCancelToken(),
        });
    }

    async deleteTable(tableId: number, locationId: number) {
        return await getDefaultAxios().delete(BuildUrl("location_service", "table", "delete", tableId?.toString(), "&locationId=" + locationId), {
            cancelToken: getCancelToken(),
            data: "{}"
        });
    }

    async downloadFile(userFileId: number) {
        return await getDefaultAxios().get(BuildUrl("file_service", "userfiles", "download", userFileId.toString()), {
            cancelToken: getCancelToken(),
            responseType: 'blob'
        });
    }

    async getPaymentPreview(request: CreatePaymentRequest): Promise<PaymentTO> {
        return await getDefaultAxios().put(BuildUrl("payment_service", "payments", "preview"), request, {
            cancelToken: getCancelToken(),
        });
    }
    
    async createPayment(request: CreatePaymentRequest): Promise<PaymentTO> {
        return await getDefaultAxios().post(BuildUrl("payment_service", "payments", "pay"), request, {
            cancelToken: getCancelToken(),
        });
    }
    
    async getUserPaymentMethods(): Promise<UserPaymentMethod[]> {
        return await getDefaultAxios().get(BuildUrl("payment_service", "userpaymentmethods", "get"), {
            cancelToken: getCancelToken(),
        });
    }
    
    async createUserPaymentMethod(userPaymentMethod: UserPaymentMethod): Promise<UserPaymentMethod> {
        return await getDefaultAxios().post(BuildUrl("payment_service", "userpaymentmethods", "create"), userPaymentMethod, {
            cancelToken: getCancelToken(),
        });
    }
    
    async setPaymentMethodAsDefault(userPaymentMethod: UserPaymentMethod): Promise<UserPaymentMethod> {
        return await getDefaultAxios().put(BuildUrl("payment_service", "userpaymentmethods", "default", userPaymentMethod.id.toString()), "{}", {
            cancelToken: getCancelToken(),
        });
    }

    async createReview(review: Review): Promise<Review> {
        return await getDefaultAxios().post(BuildUrl("location_service", "reviews", "create"), review, {
            cancelToken: getCancelToken(),
        });
    }

    async updateReview(review: Review): Promise<Review> {
        return await getDefaultAxios().put(BuildUrl("location_service", "reviews", "update", review.id.toString()), review, {
            cancelToken: getCancelToken(),
        });
    }

    async deleteReview(reviewId: number): Promise<Review> {
        return await getDefaultAxios().delete(BuildUrl("location_service", "reviews", "delete", reviewId.toString()), {
            data: "{}",
            cancelToken: getCancelToken(),
        });
    }

    async createAddress(address: Address): Promise<Address> {
        return await getDefaultAxios().post(BuildUrl("user_service", "addresses", "create"), address, {
            cancelToken: getCancelToken(),
        });
    }
    
    async getMainAddress(): Promise<Address> {
        return await getDefaultAxios().get(BuildUrl("user_service", "addresses", "main"), {
            cancelToken: getCancelToken(),
        });
    }
    
    async getAddresses(): Promise<Address[]> {
        return await getDefaultAxios().get(BuildUrl("user_service", "addresses", "get"), {
            cancelToken: getCancelToken(),
        });
    }

    async getOwnQrCode(): Promise<UserFileTO> {
        return await getDefaultAxios().get(BuildUrl("file_service", "qrusers", "get"), {
            cancelToken: getCancelToken(),
        });
    }

    async getPlans(): Promise<Plan[]> {
        return await getDefaultAxios().get(BuildUrl("payment_service", "plans", "get"), {
            cancelToken: getCancelToken(),
        });
    }

    async getLocationPlan(locationId: number): Promise<LocationPlan> {
        return await getDefaultAxios().get(BuildUrl("payment_service", "locationplans", "find", null, "&locationId=" + locationId), {
            cancelToken: getCancelToken(),
        });
    }

    async createLocationPlan(locationPlan: LocationPlan): Promise<LocationPlan> {
        return await getDefaultAxios().post(BuildUrl("payment_service", "locationplans", "create"), locationPlan, {
            cancelToken: getCancelToken(),
        });
    }

    async changeLocationPlan(locationPlan: LocationPlan): Promise<LocationPlan> {
        return await getDefaultAxios().put(BuildUrl("payment_service", "locationplans", "change"), locationPlan, {
            cancelToken: getCancelToken(),
        });
    }

    async cancelLocationPlan(locationId: number): Promise<LocationPlan> {
        return await getDefaultAxios().put(BuildUrl("payment_service", "locationplans", "cancel", null, "&locationId=" + locationId), {
            cancelToken: getCancelToken(),
        });
    }

    async getTableByCode(code: string): Promise<Table> {
        return await getDefaultAxios().get(BuildUrl("location_service", "table", "FindByCode", code), {
            cancelToken: getCancelToken(),
        });
    }
}