import Booking from "../Booking";
import Address from "./Address";
import OrderProduct from "./OrderProduct";
import { Salutation } from "./Salutation";

export default class Order {
    id: number = 0;
    salutation: Salutation = Salutation.Mr;
    firstName: string = "";
    lastName: string = "";
    street: string = "";
    houseNumber: string = "";
    additional: string = "";
    city: string = "";
    zipCode: string = "";
    phoneNumber: string = "";
    userId: number = 0;
    userName: string = "";
    locationId: number = 0;
    created: Date = new Date();
    progressStarted: Date | null = null;
    progressFinished: Date | null = null;
    delivered: Date | null = null;
    paid: Date | null = null;
    state: OrderState = OrderState.Pending;
    type: OrderType = OrderType.Local;
    orderProducts: OrderProduct[] = [];
    bookingId: number = 0;
    booking: Booking | null = null;
    deliveryAddressId: number = 0;
}

export enum OrderState {
    Pending,
    InProgress,
    DeliveryPending,
    Done,
    Cancelled
}

export enum OrderType {
    Local,
    Takeaway,
    Delivery
}