export default class EnvironmentHandler {
    static isTestEnvironment() {
        var host = window.location.hostname;

        return host === "partner-test.venisly.com" || host === "partner-test.venisly.de";
    }

    static isStageEnvironment() {
        var host = window.location.hostname;

        return host === "partner-stage.venisly.com" || host === "partner-stage.venisly.de";
    }

    static isLocalEnvironment() {
        var host = window.location.hostname;

        return host === "localhost" || host === "127.0.0.1";
    }

    static isProductionEnvironment() {
        var host = window.location.hostname;

        return host === "partner.venisly.com" || host === "partner.venisly.de";
    }

    static getMainWebsiteEnvironment() {
        if (this.isProductionEnvironment()) {
            return "https://venisly.com";
        } else if(this.isStageEnvironment()) {
            return "https://stage.venisly.com";
        } else if(this.isTestEnvironment()) {
            return "https://test.venisly.com";
        }

        var port = window.location.port;
        
        if (port === '3000') {
            return "http://localhost:3001";
        }

        return "http://localhost:3000";
    }
}