const storageNames =  {
    tokenExp: "tokenExp",
    language: "language",
    languages: "languages",
    languagePackage: "languagePackage",
    locationLanguagePackage: "locationLanguagePackage",
    user: "user",
    serverDomain: "serverDomain",
    wsDomain: "wsDomain",
    activeLocation: "activeLocation",
    location: "location",
    cashRegister: "cashRegister",
    orders: "orders",
    payments: "payments",
    paymentMethods: "paymentMethods",
    floorPlan: "floorPlan",
    activeRoom: "activeRoom",
    booking: "booking",
    myTable: "myTable",
    cartProducts: "cartProducts",
    selectedItems: "selectedItems",
    locations: "locations",
    tags: "tags",
    tmpPaymentOption: "tmpPaymentOption",
    tmpInvoiceAddress: "tmpInvoiceAddress",
    tmpDeliveryAddress: "tmpDeliveryAddress",
    tmpOrderType: "tmpOrderType",
    tmpLocationId: "tmpLocationId",
    tmpLocationName: "tmpLocationName",
    mainAddress: "mainAddress",
    qrCode: "qrCode",
    selectedOrderProducts: "selectedOrderProducts",
    activeBookings: "activeBookings",
    allergens: "allergenes",
    additives: "additives",
    productCategories: "productCategories",
    cartProductsPreview: "cartProductsPreview",
    options: "options"
}

export default storageNames;