import { Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getAcceptedCookies, setAcceptedCookies } from "../../common/cookieUtils";
import EnvironmentHandler from "../../../services/environmentHandler";

type CookieConsentDialogType = {
}

const MIN_WIDTH = 300;

const CookieConsentDialog = ({...props}: CookieConsentDialogType) => {
    const [open, setOpen] = useState<boolean>(false);
    const [preferences, setPreferences] = useState<boolean>(true);
    const [statistics, setStatistics] = useState<boolean>(true);
    const [marketing, setMarketing] = useState<boolean>(true);

    let location = useLocation();

    useEffect(() => {
        var isReactSnap = navigator.userAgent === 'ReactSnap';
        if(isReactSnap) {
            return;
        }

        if(location.pathname === "/impressum" || location.pathname === "/impressum/" || location.pathname === "/datenschutzerklaerung" || location.pathname === "/datenschutzerklaerung/") {
            return;
        }

        if(getAcceptedCookies().length === 0) {
            setOpen(true);
        }
    }, [location])
      

    const onConfirm = () => {
        var cookieNames: string[] = [];
        let domain;

        if(EnvironmentHandler.isProductionEnvironment()) {
            domain = ".venisly.com";
        } else {
            domain = window.location.hostname;
        }

        if(preferences) {
            cookieNames.push("preferences");
        }
        
        if(statistics) {
            cookieNames.push("statistics");
        }
        
        if(marketing) {
            cookieNames.push("marketing");
        }

        setAcceptedCookies(cookieNames, domain);
        setOpen(false);
    }

    const onOnlyRequired = () => {
        setPreferences(false);
        setStatistics(false);
        setMarketing(false);

        onConfirm();
    }

    const onClose = () => {
        //can not close
    }

    const domain = "https://partner.venisly.com"

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby={"cookie-dialog-title"}
            aria-describedby={"cookie-dialog-content"}
            sx={{
                '.MuiPaper-root': {
                    padding: 1,
                }
            }}
        >
            <DialogTitle
                id="cookie-dialog-title"
            >
                <Typography 
                    variant="h6" 
                    component="h1"
                    className="font-face-metropolis"
                >
                    Diese Webseite verwendet Cookies 🍪
                </Typography>
            </DialogTitle>
            <DialogContent
                id="cookie-dialog-content"
                key="cookie-dialog-content"
                sx={{
                    minWidth: MIN_WIDTH
                }}
            >
                <Typography
                    sx={{
                        mb: 2
                    }}
                >
                    Wir verwenden Cookies, um Ihnen ein optimales Webseiten-Erlebnis zu bieten. <br />
                    Dazu zählen Cookies, die für den Betrieb der Seite notwendig sind, sowie solche, 
                    die zu Statistikzwecken oder für Komforteinstellungen genutzt werden. <br />
                    Sie können selbst entscheiden, welche Kategorien Sie zulassen möchten. Bitte beachten Sie, dass auf Basis Ihrer Einstellungen womöglich nicht mehr alle Funktionalitäten der Seite zur Verfügung stehen.
                </Typography>
                <Typography
                    sx={{
                        mb: 2
                    }}
                >
                    Diese Auswahl können Sie jederzeit ändern oder Ihre Einwilligung widerrufen, 
                    indem Sie am Ende der Seite auf "Cookie-Einstellungen" klicken.
                </Typography>
                <Typography
                    sx={{
                        mb: 2
                    }}
                >
                    <a href={domain + "/impressum"}>Impressum</a> und <a href={domain + "/datenschutzerklaerung"}>Datenschutzerklärung</a> von Venisly.
                </Typography>
                <FormGroup>
                    <Grid
                        container
                        sx={{
                            mb: 2
                        }}
                    >
                        <Grid
                            item
                        >
                            <FormControlLabel 
                                control={<Checkbox defaultChecked disabled />} 
                                label="Notwendig" 
                            />
                        </Grid>
                        <Grid
                            item
                        >
                            <FormControlLabel 
                                control={<Checkbox checked={preferences} onChange={(event, checked) => setPreferences(checked)}/>} 
                                label="Präferenzen" 
                            />
                        </Grid>
                        <Grid
                            item
                        >
                            <FormControlLabel 
                                control={<Checkbox checked={statistics} onChange={(event, checked) => setStatistics(checked)}/>} 
                                label="Statistiken" 
                            />
                        </Grid>
                        <Grid
                            item
                        >
                            <FormControlLabel 
                                control={<Checkbox checked={marketing} onChange={(event, checked) => setMarketing(checked)}/>} 
                                label="Marketing" 
                            />
                        </Grid>
                    </Grid>
                    <Button
                        id="cookies-accept"
                        variant="contained"
                        onClick={onConfirm}
                        sx={{
                            mb:  1
                        }}
                    >
                        Auswahl übernehmen
                    </Button>
                    <Button
                        id="cookies-accept-required"
                        variant="outlined"
                        onClick={onOnlyRequired}
                    >
                        Nur notwendige zulassen
                    </Button>
                </FormGroup>
            </DialogContent>
        </Dialog>
    );
}

export default CookieConsentDialog;