import './App.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SnackbarProvider } from 'notistack';
import { getAcceptedCookies } from './core/common/cookieUtils';
import CookieConsentDialog from './core/components/dialogs/CookieConsentDialog';
import { Helmet } from "react-helmet-async";
import UserRequestManager from './services/api/userRequestManager';
import prerenderedLoadable from './loadableHelper';
import CssBaseline from "@mui/material/CssBaseline";
import { HelmetProvider } from 'react-helmet-async';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ScrollToTop from './core/components/ScrollToTop';

const HomeScreen = prerenderedLoadable(() => import("./screens/HomeScreen"));
const HowItWorksScreen = prerenderedLoadable(() => import("./screens/HowItWorksScreen"));
const ContactScreen = prerenderedLoadable(() => import("./screens/ContactScreen"));
const InvitationAcceptScreen = prerenderedLoadable(() => import("./screens/InvitationAcceptScreen"));
const MyLocationsScreen = prerenderedLoadable(() => import("./screens/MyLocationsScreen"));
const PrivacyPolicyScreen = prerenderedLoadable(() => import("./screens/PrivacyPolicyScreen"));
const ImprintScreen = prerenderedLoadable(() => import("./screens/ImprintScreen"));
const FaqScreen = prerenderedLoadable(() => import("./screens/FaqScreen"));
const ScannerScreen = prerenderedLoadable(() => import("./screens/ScannerScreen"));

const LoginScreen = prerenderedLoadable(() => import("./screens/account/LoginScreen"));
const RegisterScreen = prerenderedLoadable(() => import("./screens/account/RegisterScreen"));
const AccountScreen = prerenderedLoadable(() => import("./screens/account/AccountScreen"));
const LocationRegisterScreen = prerenderedLoadable(() => import("./screens/account/LocationRegisterScreen"));
const ProfileScreen = prerenderedLoadable(() => import("./screens/account/ProfileScreen"));
const ForgotPasswordScreen = prerenderedLoadable(() => import("./screens/account/ForgotPasswordScreen"));

const PartnerScreen = prerenderedLoadable(() => import("./screens/partner-area/PartnerScreen"));
const CashRegisterScreen = prerenderedLoadable(() => import("./screens/cashregister/CashRegisterScreen"));
const LinkCashRegisterScreen = prerenderedLoadable(() => import("./screens/partner-area/LinkCashRegisterScreen"));
const ProductsScreen = prerenderedLoadable(() => import("./screens/partner-area/product-settings/ProductsScreen"));
const ProductScreen = prerenderedLoadable(() => import("./screens/partner-area/product-settings/ProductScreen"));
const LocationOrdersScreen = prerenderedLoadable(() => import("./screens/partner-area/LocationOrdersScreen"));
const LocationPaymentsScreen = prerenderedLoadable(() => import("./screens/partner-area/LocationPaymentsScreen"));
const SeatPlanScreen = prerenderedLoadable(() => import("./screens/partner-area/SeatPlanScreen"));
const RoomScreen = prerenderedLoadable(() => import("./screens/partner-area/RoomScreen"));
const PartnerLayout = prerenderedLoadable(() => import("./components/partners/PartnerLayout"));
const OrderConfirmScreen = prerenderedLoadable(() => import("./screens/order-details/OrderConfirmScreen"));
const TableDetails = prerenderedLoadable(() => import("./screens/partner-area/TableDetails"));
const LocationMenuScreen = prerenderedLoadable(() => import("./screens/partner-area/location-menu/LocationMenuScreen"));
const OrdersSelectScreen = prerenderedLoadable(() => import("./screens/partner-area/OrdersSelectScreen"));
const LocationPaymentConfirmScreen = prerenderedLoadable(() => import("./screens/partner-area/LocationPaymentConfirmScreen"));
const CategoriesScreen = prerenderedLoadable(() => import("./screens/partner-area/product-settings/CategoriesScreen"));
const OptionsScreen = prerenderedLoadable(() => import("./screens/partner-area/OptionsScreen"));

const LocationMenuSettingsScreen = prerenderedLoadable(() => import("./screens/partner-area/location-settings/LocationSettingsMenuSettingsScreen"));
const LocationSettingsScreen = prerenderedLoadable(() => import("./screens/partner-area/location-settings/LocationSettingsScreen"));
const LocationSettingsGeneralScreen = prerenderedLoadable(() => import("./screens/partner-area/location-settings/LocationSettingsGeneralScreen"));
const LocationSettingsStaffScreen = prerenderedLoadable(() => import("./screens/partner-area/location-settings/LocationSettingsStaffScreen"));
const LocationSettingsOpeningHoursScreen = prerenderedLoadable(() => import("./screens/partner-area/location-settings/LocationSettingsOpeningHoursScreen"));
const LocationSettingsImagesScreen = prerenderedLoadable(() => import("./screens/partner-area/location-settings/LocationSettingsImagesScreen"));
const PaymentInfoScreen = prerenderedLoadable(() => import("./screens/partner-area/location-settings/PaymentInfoScreen"));

const PlanScreen = prerenderedLoadable(() => import("./screens/partner-area/location-settings/plans/PlanScreen"));
const PlanChangeScreen = prerenderedLoadable(() => import("./screens/partner-area/location-settings/plans/PlanChangeScreen"));

export const themeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: '#345a7f',
    },
    secondary: {
      main: '#d4c2b1',
    },
    background: {
      default: "#F5F5FA"
    },
    text: {
      primary: '#1b1c1e',
    },
    /*
    success: {
      main: '#7fbd5a',
    },
    */

    //error: {
    // main: '#db565b',
    //},
  },
  typography: {
    caption: {
      fontSize: "0.8rem"
    },
    fontSize: 16,
    fontFamily: "GlacialIndifferenceRegular"
  },
  shape: {
    borderRadius: 12
  }
};

const App = () => {

  const [initialized, setInitialized] = useState<boolean>(false);

  const theme = createTheme(themeOptions);

  useEffect(() => {
    document.addEventListener('onLanguageChange', reloadWindow);
    document.addEventListener('onUnauthorized', logout);

    const init = async () => {
      var isReactSnap = navigator.userAgent === 'ReactSnap';
      if (isReactSnap) {
        return;
      }

      if (!initialized) {
        await import("./services/languageHandler").then(languageHandler => {
          var code = languageHandler.default.getInstance().getUserLanguage();
          languageHandler.default.getInstance().loadLanguageByCode(code, false, false);
          languageHandler.default.getInstance().refresh();
        });

        setInitialized(true);
      }
    }
    init();

    return () => {
      document.removeEventListener('onLanguageChange', reloadWindow);
      document.removeEventListener('onUnauthorized', logout);
    }
  }, [])

  const reloadWindow = () => {
    window.location.reload();
  }

  const logout = async () => {
    await UserRequestManager.logout().finally(async () => {
      window.location.replace(`${window.location.protocol}//${window.location.host}/login`);
    })
  }

  return (

    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          <CssBaseline />
          <div id="app" className="App">
            <Helmet>
              <link rel="canonical" href="https://partner.venisly.com/" />
              <title>Digitale Speisekarte kostenlos testen</title>
              <meta name="description" content="Erstelle deine digitale Speisekarte in nur wenigen Minuten und lege direkt los - Automatische Übersetzung in bis zu 7 Sprachen. 30 Tage lang kostenlos testen." />
            </Helmet>
            <BrowserRouter>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <ScrollToTop />
                <CookieConsentDialog />
                <SnackbarProvider
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                />
                <Routes>
                  <Route path="/" element={<HomeScreen />} />
                  <Route path="how-it-works" element={<HowItWorksScreen />} />
                  <Route path="contact" element={<ContactScreen />} />
                  <Route path="login" element={<LoginScreen />} />
                  <Route path="forgot-password" element={<ForgotPasswordScreen />} />
                  <Route path="register" >
                    <Route index element={<RegisterScreen />} />
                    <Route path=":planName" element={<LocationRegisterScreen />} />
                  </Route>
                  <Route path="impressum" element={<ImprintScreen />} />
                  <Route path="datenschutzerklaerung" element={<PrivacyPolicyScreen />} />
                  <Route path="faq" element={<FaqScreen />} />
                  <Route path="scan" element={<ScannerScreen />} />
                  <Route path="account" element={<AccountScreen />} >
                    <Route path="profile" element={<ProfileScreen />} />
                  </Route>
                  <Route path="invitations/:invitationId">
                    <Route path="accept" element={<InvitationAcceptScreen />} />
                  </Route>

                  <Route path="my-locations" element={<MyLocationsScreen />} />

                  <Route path="locations/:locationId" element={<PartnerLayout />} >
                    <Route index element={<PartnerScreen />} />
                    <Route path="cashregister">
                      <Route index element={<CashRegisterScreen />} />
                      <Route path="link" element={<LinkCashRegisterScreen />} />
                    </Route>
                    <Route path="products">
                      <Route index element={<ProductsScreen />} />
                      <Route path="edit/:id" element={<ProductScreen />} />
                      <Route path="add" element={<ProductScreen />} />
                    </Route>
                    <Route path="categories" element={<CategoriesScreen />} />
                    <Route path="orders">
                      <Route index element={<LocationOrdersScreen />} />
                      <Route path=":extendId?" element={<LocationOrdersScreen />} />
                    </Route>
                    <Route path="payments">
                      <Route index element={<LocationPaymentsScreen />} />
                    </Route>
                    <Route path="options">
                      <Route index element={<OptionsScreen />} />
                    </Route>
                    <Route path="seat-plan">
                      <Route index element={<SeatPlanScreen />} />
                      <Route path="room/:id" >
                        <Route index element={<RoomScreen />} />
                        <Route path="tables/:tableCode">
                          <Route index element={<TableDetails />} />
                          <Route path="order">
                            <Route index element={<LocationMenuScreen />} />
                            <Route path="confirm" element={<OrderConfirmScreen />} />
                          </Route>
                          <Route path="pay">
                            <Route index element={<OrdersSelectScreen />} />
                            <Route path="confirm" element={<LocationPaymentConfirmScreen />} />
                          </Route>
                        </Route>
                      </Route>
                    </Route>
                    <Route path="settings">
                      <Route index element={<LocationSettingsScreen />} />
                      <Route path="general" element={<LocationSettingsGeneralScreen />} />
                      <Route path="staff" element={<LocationSettingsStaffScreen />} />
                      <Route path="openingHours" element={<LocationSettingsOpeningHoursScreen />} />
                      <Route path="images" element={<LocationSettingsImagesScreen />} />
                      <Route path="paymentinfo" element={<PaymentInfoScreen />} />
                      <Route path="menu" element={<LocationMenuSettingsScreen />} />
                      <Route path="plan">
                        <Route index element={<PlanScreen />} />
                        <Route path="change" element={<PlanChangeScreen />} />
                      </Route>
                    </Route>
                  </Route>
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </LocalizationProvider>
            </BrowserRouter>
          </div>
        </HelmetProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
}

export default App;
