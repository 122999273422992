import axios, { CancelTokenSource } from "axios";
import { baseUrlMasterService, getDomain } from "./requestUtils";
import deviceStorage from "../deviceStorage";
import storageNames from "../storageNames";
import { getCurrentDateTimeUTC, minusMinutes } from "../../common/utils";

const tokenAxios = axios.create({
    withCredentials: true
});

tokenAxios.interceptors.request.use((config) => {
    var domain = getDomain();

    config.baseURL = domain + baseUrlMasterService;
    config.headers!['Content-Type'] = 'application/json';
    return config;
}, (error) => {
    console.log("Token could not be refreshed: " + JSON.stringify(error));
    return error;
});

export default class TokenHandler {


    static myInstance: TokenHandler;

    static _cancelToken: CancelTokenSource;
    isRefreshing: boolean = false;

    /**
     * @returns {TokenHandler}
     */
    static getInstance(): TokenHandler {
        if (TokenHandler.myInstance === null || TokenHandler.myInstance === undefined) {
            TokenHandler.myInstance = new TokenHandler();
            TokenHandler._cancelToken = axios.CancelToken.source();
        }

        return this.myInstance;
    }

    async refresh(force: boolean = false) {
        if(!!this.isRefreshing) {
            return;
        }

        var expDate = deviceStorage.getItem(storageNames.tokenExp);
        if(!expDate) {
            return;
        }

        if (force || minusMinutes(expDate, 1).getTime() <= getCurrentDateTimeUTC().getTime()) { // If 1 minute or less left refresh api token
            this.isRefreshing = true;
            
            try {
                let response = await tokenAxios.post("/token/refresh", "{}");
                deviceStorage.saveItem(storageNames.tokenExp, response.data.token.jwtExpireDate);

            } catch(error: any) {
                if(error.response.status === 401 || error.response.status === 403) {
                    document.dispatchEvent(new Event("onUnauthorized"));
                }

                return;
            } finally {
                this.isRefreshing = false;
            }
        }
    }
}