import LocationSettings from "../models/LocationSettings";
import Location from "../models/locations/Location";
import cachedStorage from "./cachedStorage";
import deviceStorage from "./deviceStorage";
import storageNames from "./storageNames";
import User from "../models/User";
import CachedValue from "../models/CachedValue";
import RequestManager from "./api/requestManager";
import OptionsWrapper from "../models/products/OptionsWrapper";
import UserRequestManager from "./api/userRequestManager";

export default class LocationHandler {

    /**
     * Gets the selected location. If the provided location id differs from the cached location then a new request gets executed.
     */
    static async getLocationOptions(locationCode: string, force: boolean = false): Promise<CachedValue<OptionsWrapper>> {
        var wrapper = deviceStorage.getItem(storageNames.options);

        if(!wrapper || wrapper.value.locationCode !== locationCode) {
            return await cachedStorage.getValue<OptionsWrapper>(storageNames.options, async () => {
                var options = await RequestManager.getInstance().getOptions(locationCode);

                var newWrapper = new OptionsWrapper();
                newWrapper.locationCode = locationCode;
                newWrapper.options = options;

                return newWrapper;
            }, 60, true);

        } else {
            return await cachedStorage.getValue<OptionsWrapper>(storageNames.options, async () => {
                var options = await RequestManager.getInstance().getOptions(locationCode);

                var newWrapper = new OptionsWrapper();
                newWrapper.locationCode = locationCode;
                newWrapper.options = options;

                return newWrapper;
            }, 60, force);
        }
    }

    /**
     * Gets the location of the current user.
     * @returns The location of the user.
     */
    static async getLocation(): Promise<CachedValue<Location>> {
        var user = await cachedStorage.getValue<User>(storageNames.user, async () => await UserRequestManager.getUser());

        return await cachedStorage.getValue<Location>(storageNames.location, async () => await RequestManager.getInstance().findLocation(user.value.additionalData.locationId), 60);
    }

    /**
     * Gets the selected location. If the provided location code differs from the cached location then a new request gets executed.
     */
    static async getSelectedLocation(locationCode: string, force: boolean = false): Promise<CachedValue<Location>> {
        var location = deviceStorage.getItem(storageNames.activeLocation);

        if(!location || location.value.code !== locationCode) {
            var newLocation = await RequestManager.getInstance().getLocation(locationCode);

            return await cachedStorage.getValue<Location>(storageNames.activeLocation, async () => await RequestManager.getInstance().findLocation(newLocation.id), 60, true);
        } else {
            return await cachedStorage.getValue<Location>(storageNames.activeLocation, async () => await RequestManager.getInstance().findLocation(location.value.id), 60, force);
        }
    }

    /**
     * Gets the selected location. If the provided location id differs from the cached location then a new request gets executed.
     */
    static async getCachedLocation(force: boolean = false): Promise<CachedValue<Location>> {
        var location = deviceStorage.getItem(storageNames.activeLocation);

        return await cachedStorage.getValue<Location>(storageNames.activeLocation, async () => await RequestManager.getInstance().findLocation(location.value.id), 60, force);
    }

    /**
     * Sets the selected location.
     */
    static async setSelectedLocation(locationId: number): Promise<CachedValue<Location>> {
        return await cachedStorage.getValue<Location>(storageNames.activeLocation, async () => await RequestManager.getInstance().findLocation(locationId), 60, true);
    }

    /**
     * Checks whether the location has the required plan or higher.
     * @param requiredPlan The name of the plan.
     * @param locationPlan The plan of the location to check.
     */
    static isPlanOrHigher(requiredPlan: string | null, locationPlan: string) {
        if(requiredPlan === null || requiredPlan.toLocaleLowerCase() === "starter") {
            //All locations are at least starter
            return true;
        }

        requiredPlan = requiredPlan.toLocaleLowerCase();
        locationPlan = locationPlan.toLocaleLowerCase();

        if(requiredPlan === "plus") {
            return locationPlan === "plus" || locationPlan === "premium" || locationPlan === "deluxe";
        } else if(requiredPlan === "premium") {
            return locationPlan === "premium"  || locationPlan === "deluxe";
        } else if(requiredPlan === "deluxe") {
            return locationPlan === "deluxe";
        } else {
            return false;
        }
    }

    static isPlan(requiredPlan: string, locationPlan: string) {
        return requiredPlan.toLocaleLowerCase() === locationPlan.toLocaleLowerCase();
    }
}