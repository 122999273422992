import axios, { AxiosInstance, CancelTokenSource } from "axios";
import TokenHandler from "./tokenHandler";
import { baseUrlMasterService, getDomain } from "./requestUtils";
import storageNames from "../storageNames";
import deviceStorage from "../deviceStorage";

const gatewayAxios = axios.create({
    withCredentials: true
});

gatewayAxios.defaults.withCredentials = true;

const uploadAxios = axios.create({
    withCredentials: true
});

gatewayAxios.interceptors.request.use(async (config) => {
    var domain = getDomain();
    await TokenHandler.getInstance().refresh();
    
    config.baseURL = domain + baseUrlMasterService;
    config.headers!['Content-Type'] = 'application/json';
    config.withCredentials = true;
    return config;
}, (error) => {
    console.log("Request failed: " + error.response["_response"]);
    return Promise.reject(error);
});

gatewayAxios.interceptors.response.use(async (response) => {
    if(response.data.token) {
        deviceStorage.saveItem(storageNames.tokenExp, response.data.token.jwtExpireDate);
    }

    if(response.config.responseType === "arraybuffer") {
        var rAsText = JSON.stringify(response.request);

        var beginText = "\"_response\":\"";
        var start = rAsText.indexOf(beginText) + beginText.length;

        var imageBase64 = rAsText.substring(
            start, 
            rAsText.indexOf("\"", start)
        );
        return "data:image/png;base64," + imageBase64;
    }

    return response.data.data !== undefined ? response.data.data : response.data;
}, (error) => {
    if(error.response.status === 401) {
        document.dispatchEvent(new Event("unAuthorized"));
    }

    console.log("Error: " + JSON.stringify(error.response));
    console.log("Request failed (response):" + JSON.stringify(error));
    return Promise.reject(error);
});

uploadAxios.interceptors.request.use(async (config) => {
    var domain = getDomain();
    await TokenHandler.getInstance().refresh();

    config.baseURL = domain + baseUrlMasterService;
    config.headers!['Content-Type'] = 'multipart/form-data';
    return config;
}, (error) => {
    console.log("Request failed: " + error.response["_response"]);
    return Promise.reject(error);
});

uploadAxios.interceptors.response.use(async (response) => {
    //console.log("Request successful:" + JSON.stringify(response));
    if(response.data.token) {
        deviceStorage.saveItem(storageNames.tokenExp, response.data.token.jwtExpireDate);
    }

    if(response.config.responseType === "arraybuffer") {
        var rAsText = JSON.stringify(response.request);

        var beginText = "\"_response\":\"";
        var start = rAsText.indexOf(beginText) + beginText.length;

        var imageBase64 = rAsText.substring(
            start, 
            rAsText.indexOf("\"", start)
        );
        return "data:image/png;base64," + imageBase64;
    }

    return response.data.data !== undefined ? response.data.data : response.data;
}, (error) => {
    //if(error.response.status === 401) {
        //NavigationActions.navigate({ routeName: 'home' });
        //deviceStorage.removeItem(storageNames.apiToken);
    //}

    console.log("Error: " + JSON.stringify(error.response));
    console.log("Request failed (response):" + JSON.stringify(error));
    return Promise.reject(error);
});


export default class AxiosProvider {

    static myInstance: AxiosProvider;

    _cancelToken: CancelTokenSource = axios.CancelToken.source();



    /**
     * @returns {RequestManager}
     */
    static getInstance(): AxiosProvider {
        if (AxiosProvider.myInstance === null || AxiosProvider.myInstance === undefined) {
            AxiosProvider.myInstance = new AxiosProvider();
            AxiosProvider.myInstance._cancelToken = axios.CancelToken.source();
        }

        return this.myInstance;
    }

    getGatewayAxios(): AxiosInstance {
        return gatewayAxios;
    }

    getUploadAxios(): AxiosInstance {
        return uploadAxios;
    }

    getCancelToken(): CancelTokenSource {
        return this._cancelToken;
    }
}