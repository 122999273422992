import { cookies } from "./cookies";

/**
 * Gets a cookie by the name.
 * @param cname The name of the cookie
 * @returns The value of the cookie
 */
export function getCookie(cname: string) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
}

/**
 * Sets a cookie.
 * @param cname The name of the cookie
 * @param cvalue The value of the cookie
 * @param exdays The expiration date of the cookie in days
 */
export function setCookie(cname: string, cvalue: string, exdays: number, domain: string | null = null) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();

    if(domain) {
      document.cookie = cname + "=" + cvalue + ";" + expires + ";domain=" + domain + ";SameSite=Lax";
    } else {
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;SameSite=Lax";
    }
}

/**
 * Sets a cookie.
 * @param cname The name of the cookie
 * @param cvalue The value of the cookie
 * @param exdays The expiration date of the cookie in days
 */
export function setCookieNeverExpires(cname: string, cvalue: string, domain: string | null = null) {
  const d = new Date();
  d.setTime(d.getTime() + (365*20*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();

  if(domain) {
    document.cookie = cname + "=" + cvalue + ";" + expires + ";domain=" + domain + ";SameSite=Lax";
  } else {
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;SameSite=Lax";
  }
}

/**
 * Checks whether a cookie exists or not.
 * @param cname The name of the cookie
 * @returns A boolean indicating whether the cookie exists or not.
 */
export function hasCookie(cname: string) {
    return getCookie(cname) !== "";
}

export function getAcceptedCookies(): string[] {
  var myCookies = getCookie(cookies.acceptedCookies);

  if(!myCookies || myCookies.trim() === "") {
    return [];
  }

  return JSON.parse(myCookies);
}

export function setAcceptedCookies(selectedCookies: string[], domain: string): void {
  setCookie(cookies.acceptedCookies, JSON.stringify(selectedCookies), 50000, domain);
}